import { Form, OverlayTrigger, Tooltip, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import TextBoxField from "./TextBoxField";
import DropDownMultipleChoiceField from "./DropDownMultipleChoiceField";
import RepeatableQuestionField from "./RepeatableQuestionField";
import ImageUploadField from "./ImageUploadField";

import { ordinalSuffixOf, getAnswer } from "@/Utility";
import ConfirmModal from "@/components/modals/ConfirmModal";
import RangeField from "./RangeField";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SupportiveDocumentField from "./SupportiveDocumentField";

function Question({
    setValue,
    question,
    errors,
    register,
    unregister,
    index,
    inputNameSuffix,
    sectionIndex,
    repeatIndex,
    childQuestion,
    watch,
    resetField,
    answers,
    auditMode,
    totalSection,
    assessmentSection,
    lengthOfQuestion,
    onItemHover,
}) {
    const { t } = useTranslation();

    const [answer, setAnswer] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    //const [selectedChoice, setSelectedChoice] = useState();
    const showHelpText = () => {
        setShowHelp(true);
    };
    const handleCancel = () => {
        setShowHelp(false);
    };

    const renderTooltip = (props) => (
        <Tooltip className="" {...props}>
            {t("pages.assessments.help_text")}
        </Tooltip>
    );

    useEffect(() => {
        if (answers && answers.length !== 0) {
            setAnswer(getAnswer(answers, repeatIndex, question));
        }
    }, [answers, question]); //selectedChoice

    return (
        <>
            <ConfirmModal
                show={showHelp}
                title={question.label}
                body={question.help_text}
                handleCancel={handleCancel}
            />
            <Form.Group className="py-2">
                <input
                    type="hidden"
                    name="question_id"
                    value={question.id}
                    {...register(`${inputNameSuffix}.question_id`)}
                />
                <input
                    type="hidden"
                    name="type"
                    value={question.type}
                    {...register(`${inputNameSuffix}.type`)}
                />
                <input
                    type="hidden"
                    name="required"
                    value={question.required}
                    {...register(`${inputNameSuffix}.required`)}
                />
                <input
                    type="hidden"
                    name="required"
                    value={question.select_multiple}
                    {...register(`${inputNameSuffix}.select_multiple`)}
                />
                <input
                    type="hidden"
                    name="answer_is_choice"
                    value={[8, 9, 12, 13, 14].includes(question.type)}
                    {...register(`${inputNameSuffix}.answer_is_choice`)}
                />
                {![3, 11, 14].includes(question.type) && (
                    <div className="d-flex justify-content-between align-items-center question-heading">
                        {childQuestion ? (
                            <p className="repeatable-questions-label mb-1">
                                {repeatIndex != null
                                    ? ordinalSuffixOf(repeatIndex + 1)
                                    : null}{" "}
                                {question.label}
                                {question.required ? (
                                    <span className="text-danger">*</span>
                                ) : null}
                            </p>
                        ) : (
                            <h6 className="my-3 fw-bold">
                                {sectionIndex + 1}.{index + 1} -{" "}
                                {question.label}
                                {question.required ? (
                                    <span className="text-danger">*</span>
                                ) : null}
                            </h6>
                        )}
                        {question.help_text != null && (
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip}
                            >
                                <div
                                    role="button"
                                    onClick={() => showHelpText()}
                                    className="question-mark my-2"
                                >
                                    ?
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                )}
                {[4, 5, 7].includes(question.type) && (
                    <TextBoxField
                        type={question.type}
                        setValue={setValue}
                        register={register}
                        questionIndex={index}
                        question={question}
                        inputNameSuffix={inputNameSuffix}
                        errors={errors}
                        sectionIndex={sectionIndex}
                        answer={answer}
                        auditMode={auditMode}
                    />
                )}
                {[11].includes(question.type) && (
                    <RangeField
                        type={question.type}
                        setValue={setValue}
                        register={register}
                        questionIndex={index}
                        question={question}
                        inputNameSuffix={inputNameSuffix}
                        errors={errors}
                        sectionIndex={sectionIndex}
                        answer={answer}
                        auditMode={auditMode}
                        watch={watch}
                        childQuestion={childQuestion}
                    />
                )}
                {[10].includes(question.type) && (
                    <ImageUploadField
                        watch={watch}
                        errors={errors}
                        questionIndex={index}
                        question={question}
                        setValue={setValue}
                        inputNameSuffix={inputNameSuffix}
                        register={register}
                        resetField={resetField}
                        answer={answer}
                        auditMode={auditMode}
                    />
                )}
                {[8, 9, 12, 13].includes(question.type) && (
                    <DropDownMultipleChoiceField
                        type={question.type}
                        setValue={setValue}
                        watch={watch}
                        register={register}
                        questionIndex={index}
                        question={question}
                        inputNameSuffix={inputNameSuffix}
                        errors={errors}
                        sectionIndex={sectionIndex}
                        answer={answer}
                        unregister={unregister}
                        auditMode={auditMode}
                        // selectedChoice={selectedChoice}
                        //setSelectedChoice={setSelectedChoice}
                    />
                )}
                {[3, 6].includes(question.type) && (
                    <>
                        <RepeatableQuestionField
                            sectionIndex={sectionIndex}
                            questionIndex={index}
                            question={question}
                            errors={errors}
                            register={register}
                            unregister={unregister}
                            inputNameSuffix={inputNameSuffix}
                            watch={watch}
                            setValue={setValue}
                            resetField={resetField}
                            answers={answers}
                            auditMode={auditMode}
                        />
                    </>
                )}
                {/* {totalSection - 1 === sectionIndex && (
                    <>
                        <h6 className="my-3 fw-bold">Admin Comment</h6>
                        <Form.Control
                            as="textarea"
                            disabled={false}
                            name="admin_comment"
                            defaultValue={totalSection}
                        />
                        out
                    </>
                )} */}
                {[14].includes(question.type) && (
                    <>
                        <SupportiveDocumentField
                            type={question.type}
                            setValue={setValue}
                            watch={watch}
                            register={register}
                            questionIndex={index}
                            question={question}
                            inputNameSuffix={inputNameSuffix}
                            errors={errors}
                            sectionIndex={sectionIndex}
                            answer={answer}
                            unregister={unregister}
                            auditMode={auditMode}
                            assessmentSection={assessmentSection}
                            lengthOfQuestion={lengthOfQuestion}
                            onItemHover={onItemHover}
                        />
                    </>
                )}
            </Form.Group>
        </>
    );
}

export default Question;
