import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import LoadingCover from '@/components/LoadingCover'
import Location from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import PageActionSection from '@/components/PageActionSection'
import BackButton from '@/views/components/Buttons/BackButton'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import AssessmentCertificate from '@/views/components/assessments/AssessmentCertificate'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import LOCATION from '@/constants/Location'
import CircleCheck from '@/components/icons/CircleCheck'
import LoadingButton from '@/components/LoadingButton'

function SendAuditReport () {

  const { t } = useTranslation()
  const auth = useAuth()
  let { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const [auditReportSent, setAuditReportSent] = useState(false)
  const [sendingReport, setSendingReport] = useState(false)

  const onBackButton = () => {

  }

  const sendAuditReport = (values) => {
    setSendingReport(true)
    auth.putRequest(`${LOCATION.ASSESSMENTS.API.path}/audit-report/${id}`, values)
      .then(response => {
        setAuditReportSent(true)
        setSendingReport(false)
      })
      .catch(error => {
        setSendingReport(false)
      })

  }

  const schema = yup.object().shape({
    audit_report_message: yup.string() //.required(t('common.form_validation.is_required', { attribute: t('common.form.message') }))

  })

  const {
          resetField,
          handleSubmit,
          register,
          setValue,
          watch,
          unregister,
          formState: { errors },
        } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    let request = auth.getRequest(`${Location.ASSESSMENTS.API.path}/${id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  if (auditReportSent) {
    return (

      <div className="container request-sent d-flex justify-content-center align-items-center h-100">
        <Card className="border-0">
          <Card.Body className="text-center">
            <CircleCheck/>
            <h3 className="my-4">{t('pages.assessments.audit.report_sent')}</h3>
            <Link className="btn btn-primary text-white" to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`}>
              {t('pages.assessments.buttons.back_to_assessments')}
            </Link>
          </Card.Body>
        </Card>
      </div>
    )
  }
  return (
    <>
      <PageActionSection
        prepend={[<BackButton onBackButton={onBackButton}/>]}
        title={t('pages.assessments.request_audit')}
        type="primary"
      />
      <div className="container audit-request-form">
        <Row>

          <Col className="col-12 col-md-6">
            <AssessmentCertificate assessment={assessment}/>
          </Col>

          <Col className="col-12 col-md-6 d-flex align-items-center">
            <div className="w-75">
              <h3 className="fw-bold mb-3">{t('pages.assessments.send_updated_audit')}</h3>

              <p className="mt-3 mb-4">
                {t('pages.assessments.send_message_with_completed_audit')}
              </p>

              <Form noValidate onSubmit={handleSubmit(sendAuditReport)}>
                <Form.Group>
                  <Form.Label>
                    {t('common.form.name')}
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    value={auth.user.name}
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label>
                    {t('common.form.your_message')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={!!(errors && errors.audit_report_message)}
                    {...register('audit_report_message')}
                    placeholder={t('common.form.type_here')}
                  />
                </Form.Group>

                <Form.Control.Feedback type="invalid">
                  {errors.audit_report_message && errors.audit_report_message.message}
                </Form.Control.Feedback>

                <div className="d-grid gap-2 my-4 pt-4">
                  <LoadingButton
                    type="submit"
                    className="text-white"
                    loading={sendingReport}
                    titleTranslationKey="pages.assessments.buttons.send_audit_report_to_client"
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SendAuditReport
