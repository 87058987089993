import Question from "./Question";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Plus from "@/components/icons/Plus";
import { ordinalSuffixOf, getAnswer } from "@/Utility";

function RepeatableQuestionField({
    question,
    errors,
    register,
    unregister,
    questionIndex,
    inputNameSuffix,
    sectionIndex,
    watch,
    setValue,
    resetField,
    answers,
    auditMode,
}) {
    const { t } = useTranslation();
    const [questionChildren, setQuestionChildren] = useState([]);
    const [otherQuestion, setOtherQuestion] = useState(null);
    const [otherQuestions, setOtherQuestions] = useState([]);
    const [groupShowOther, setGroupShowOther] = useState(false);
    // Adds a single instance of the question's children
    const addQuestionRepeat = (repeatCount=0) => {
        if (
            !questionChildren.some((child) => child.id === question.children.id) || repeatCount === 1
        ) {
            setQuestionChildren([...questionChildren, question.children]);
        }
    };

    // Removes the last added instance of the question's children
    const removeQuestionRepeat = () => {
        let children = [...questionChildren];
        if (children.length > 0) {
            unregister(`${inputNameSuffix}.children[${children.length - 1}]`);
            children.pop();
            setQuestionChildren(children);
        }
    };
    const addOtherQuestion = () => {
        let questions = otherQuestions;
        questions.push(otherQuestion);
        setOtherQuestions([].concat(questions));
    };

    // Handles cases where answers exist
    const handleAnswers = () => {
        const specificQuestionId = question.children[0]?.id || 0;
        const getAnswersByQuestionId = (answers, questionId) => {
            return answers
                ? answers.filter((answer) => answer.question_id === questionId)
                : [];
        };
        const filteredAnswers = getAnswersByQuestionId(
            answers,
            specificQuestionId
        );

        // Add instances based on the number of answers
        if (filteredAnswers.length > 0) {
            setQuestionChildren(filteredAnswers.map(() => question.children));
        } else {
            // No answers exist, so just add one instance
            addQuestionRepeat();
        }
    };

    useEffect(() => {
        if (question && question.children) {
            handleAnswers();
        }
        // Find and set other questions if they exist
        let otherQuestion = question.children.find((child) => child.is_other);
        setOtherQuestion(otherQuestion);
    }, [question, answers]);

    useEffect(() => {
        if (otherQuestion && answers) {
            let otherQuestionAnswers = answers.filter(
                (answer) => answer.question_id === otherQuestion.id
            );
            let questions = [];
            otherQuestionAnswers.forEach(() => {
                questions.push(otherQuestion);
            });
            setOtherQuestions(questions);
        }
    }, [answers, otherQuestion]);

    return (
        <>
            {questionChildren.map((children, repeatIndex) => {
                return (
                    <div key={repeatIndex}>
                        {children.map((childQuestion, childIndex) => {
                            if (childQuestion.is_other && !groupShowOther) {
                                return null;
                            }
                            return (
                                <Question
                                    key={childIndex}
                                    repeatIndex={
                                        question.type === 3 ? repeatIndex : null
                                    }
                                    childQuestion={question}
                                    question={childQuestion}
                                    errors={
                                        errors &&
                                        errors[questionIndex] &&
                                        errors[questionIndex].children &&
                                        errors[questionIndex].children[
                                            repeatIndex
                                        ]
                                            ? errors[questionIndex].children[
                                                  repeatIndex
                                              ]
                                            : {}
                                    }
                                    register={register}
                                    unregister={unregister}
                                    index={childIndex}
                                    watch={watch}
                                    resetField={resetField}
                                    inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${childIndex}]`}
                                    sectionIndex={sectionIndex}
                                    setValue={setValue}
                                    answers={answers}
                                    auditMode={auditMode}
                                />
                            );
                        })}
                        {otherQuestions.map(
                            (otherChildQuestion, OtherIndex) => (
                                <Question
                                    key={OtherIndex + children.length}
                                    repeatIndex={OtherIndex}
                                    childQuestion={question}
                                    question={otherChildQuestion}
                                    errors={
                                        errors &&
                                        errors[questionIndex] &&
                                        errors[questionIndex].children &&
                                        errors[questionIndex].children[
                                            repeatIndex
                                        ]
                                            ? errors[questionIndex].children[
                                                  repeatIndex
                                              ]
                                            : {}
                                    }
                                    register={register}
                                    unregister={unregister}
                                    index={OtherIndex + children.length}
                                    watch={watch}
                                    resetField={resetField}
                                    inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${
                                        children.length + OtherIndex
                                    }]`}
                                    sectionIndex={sectionIndex}
                                    setValue={setValue}
                                    answers={answers}
                                    auditMode={auditMode}
                                />
                            )
                        )}
                    </div>
                );
            })}
            {questionChildren.length > 1 &&
                !auditMode &&
                question.type !== 6 && (
                    <Button
                        variant="link"
                        className="p-0 mb-1 mt-3"
                        onClick={removeQuestionRepeat}
                    >
                        <span className="text-danger">
                            {t("common.remove", { attribute: question.label })}
                        </span>
                    </Button>
                )}
            {!auditMode && !question.add_other && question.type !== 6 && (
                <div className="d-grid gap-2 mt-3">
                    {/* <Button onClick={addQuestionRepeat}> */}
                    <Button onClick={() => addQuestionRepeat(1)}>
                        <span className="text-white">
                            {t("common.add", { attribute: question.label })}
                        </span>
                        <Plus />
                    </Button>
                </div>
            )}
            {question.add_other && otherQuestion && (
                <div className="d-grid gap-2 mt-3">
                    <Button variant="success" onClick={addOtherQuestion}>
                        <span className="text-white">
                            {t("common.add", {
                                attribute: otherQuestion.label,
                            })}
                        </span>
                        <Plus />
                    </Button>
                </div>
            )}
        </>
    );
}

export default RepeatableQuestionField;
